// DEV
// const config = {
//     superStudentAuth: 'http://103.41.205.87:80/api/v1/auth',
//     superStudent: 'http://103.41.205.87:80/api/v1/application',
//     masterKey: 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ',
//     ihmsApi: 'http://103.41.205.87:80/ihms',
// };

// PROD
const config = {
    superStudentAuth: 'https://superstudent.niecindonesia.com/api/v1/auth',
    superStudent: 'https://superstudent.niecindonesia.com/api/v1/application',
    masterKey: 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ',
    ihmsApi: 'https://superstudent.niecindonesia.com/ihms',
};

export default config;
