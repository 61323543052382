import { BrowserRouter } from 'react-router-dom';

// routes
import { AllRoutes } from './index';
import React from 'react';

const Routes = () => {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
